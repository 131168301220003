<template lang="pug">
div
  Header(v-if="!withOutHeader")
  .col-12.contain.color-colmedica-gray.mt-4(v-if="branchInfo && branchInfo.branch_name" v-openlog)
    p(style="font-size: 1.2rem; text-align: center;")
      | 
      b.ml-2.mr-2 {{ branchInfo.branch_name }}
      | 
    p(style="font-size: 1.2rem; text-align: center;") En este momento no podemos atenderte, contáctanos en nuestro horario de atención:
    p(style="font-size: 1.2rem; text-align: center;")
    p(style="font-size: 1.2rem; text-align: center;" v-if="branchInfo.activeBranchMessage") {{ branchInfo.activeBranchMessage }}
    p(style="font-size: 2rem; text-align: center; margin-top: 30vh;" v-if="!withOutClose") Ya puedes cerrar esta ventana.
  .col-12.contain.color-colmedica-gray.mt-4(v-else v-openlog)
    p(style="font-size: 1.2rem; text-align: center;") La sede solicitada es inválida.
    p(style="font-size: 2rem; text-align: center; margin-top: 30vh;" v-if="!withOutClose") Ya puedes cerrar esta ventana.
</template>

<script>
import { mapState } from "vuex";
import Header from "./autoservice/components/Header";
export default {
  name: "outOperations",

  components: {
    Header
  },

  props: ["withOutClose", "withOutHeader"],

  computed: {
    ...mapState(["branchInfo"])
  }
};
</script>
